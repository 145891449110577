import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView
} from 'angular-calendar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { DatePipe } from '@angular/common';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

export interface Class {
  id: string;
  start: Date,
  end: Date,
  title: string,
  color: any,
  actions: any
};

@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['styles.css'],
  templateUrl: 'template.html'
})
export class DemoComponent {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('modalContent_class_edit', { static: true }) modalContent_class_edit: TemplateRef<any>;
  @ViewChild('modalContent_class_detail', { static: true }) modalContent_class_detail: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  classes: Array<Class> = [];
  class: Class;
  school: string = '';

  coursename: string = '';
  startdate: string = '';
  enddate: string = '';
  begintime: string = '';
  endtime: string = '';
  coursetype: string = '';
  paidtype: string = '';
  paidfee: any;

  model: any = {};
  fee_hour: string = '';
  fee_time: string = '';
  fee_upfront: string = '';
  fee_weekly: string = '';
  fee_biweekly: string = '';
  fee_monthly: string = '';
  is_fee_hour_edit: boolean = false;
  is_fee_time_edit: boolean = true;
  is_fee_upfront_edit: boolean = true;
  is_fee_weekly_edit: boolean = true;
  is_fee_biweekly_edit: boolean = true;
  is_fee_monthly_edit: boolean = true;

  classaddress: any;
  address: string = '';
  classid: any;
  isChecked: boolean = false;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent_class_edit('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.classid = event.id;
        if (confirm('Do you want to delete the class?')) {
          this.delete();
        }
        else {
          //do nothing
        }

        //this.events = this.events.filter(iEvent => iEvent !== event);
        //this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[];

  activeDayIsOpen: boolean = true;

  constructor(
    public http: HttpClient,
    private modal: NgbModal,
    private datePipe: DatePipe,
    private routerInfo: ActivatedRoute
  ) {

    this.routerInfo.queryParams.subscribe(params => {
      this.school = params['school'];
      console.log('school id:' + this.school);
    });
    //this.school = Cookie.get('currentUser');

    //radio default charge type
    this.model.options = 1;

    setTimeout(() => {
      this.getClassesBySchoolId();
      this.getClassAddress();
    }, 500);

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  handleEvent_class_edit(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.classid = event.id;
    this.editwindow();
  }

  handleEvent_class_detail(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.classid = event.id;

    var headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    var link = '/api/learningcentre/class/get/' + this.classid;
    console.log(link);
    this.http.get(link)
      .subscribe(data => {

        console.log(data);
        if (data[0][0].noremind == true) {
          this.isChecked = true;
        } else {
          this.isChecked = false;
        }
        this.coursename = data[0][0].class_name;
        this.startdate = this.datePipe.transform(data[0][0].start_date, 'yyyy-MM-dd');
        this.enddate = this.datePipe.transform(data[0][0].end_date, 'yyyy-MM-dd');
        this.begintime = data[0][0].class_time_begin;
        this.endtime = data[0][0].class_time_end;
        this.coursetype = data[0][0].class_type;
        this.address = data[0][0].address;
        this.paidtype = data[0][0].paid_type;
        if (this.paidtype == '1') {
          this.model.options = 1;
          this.fee_hour = data[0][0].paid_fee;
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = false;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '2') {
          this.model.options = 2;
          this.fee_hour = '';
          this.fee_time = data[0][0].paid_fee;
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = false;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '3') {
          this.model.options = 3;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = data[0][0].paid_fee;
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = false;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '4') {
          this.model.options = 4;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = data[0][0].paid_fee;
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = false;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '5') {
          this.model.options = 5;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = data[0][0].paid_fee;
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = false;
          this.is_fee_monthly_edit = true;
        } else {
          this.model.options = 6;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = data[0][0].paid_fee;
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = false;
        }

        this.modal.open(this.modalContent_class_detail, { size: 'lg' });

      }, error => {
        console.log("get classes error!" + error);
      });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  gotoedit() {
    this.modal.dismissAll(this.modalContent_class_detail);
    this.editwindow();
  }

  gotodelete() {
    if (confirm('Do you want to delete the class?')) {
      this.modal.dismissAll(this.modalContent_class_detail);
      this.delete();
    }
    else {
      //do nothing
    }
  }

  editwindow() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    var link = '/api/learningcentre/class/get/' + this.classid;
    console.log(link);
    this.http.get(link)
      .subscribe(data => {

        console.log(data);
        if (data[0][0].noremind == true) {
          this.isChecked = true;
        } else {
          this.isChecked = false;
        }
        this.coursename = data[0][0].class_name;
        this.startdate = this.datePipe.transform(data[0][0].start_date, 'yyyy-MM-dd');
        this.enddate = this.datePipe.transform(data[0][0].end_date, 'yyyy-MM-dd');
        this.begintime = data[0][0].class_time_begin;
        this.endtime = data[0][0].class_time_end;
        this.coursetype = data[0][0].class_type;
        this.address = data[0][0].address;
        this.paidtype = data[0][0].paid_type;
        if (this.paidtype == '1') {
          this.model.options = 1;
          this.fee_hour = data[0][0].paid_fee;
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = false;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '2') {
          this.model.options = 2;
          this.fee_hour = '';
          this.fee_time = data[0][0].paid_fee;
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = false;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '3') {
          this.model.options = 3;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = data[0][0].paid_fee;
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = false;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '4') {
          this.model.options = 4;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = data[0][0].paid_fee;
          this.fee_biweekly = '';
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = false;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = true;
        } else if (this.paidtype == '5') {
          this.model.options = 5;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = data[0][0].paid_fee;
          this.fee_monthly = '';
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = false;
          this.is_fee_monthly_edit = true;
        } else {
          this.model.options = 6;
          this.fee_hour = '';
          this.fee_time = '';
          this.fee_upfront = '';
          this.fee_weekly = '';
          this.fee_biweekly = '';
          this.fee_monthly = data[0][0].paid_fee;
          this.is_fee_hour_edit = true;
          this.is_fee_time_edit = true;
          this.is_fee_upfront_edit = true;
          this.is_fee_weekly_edit = true;
          this.is_fee_biweekly_edit = true;
          this.is_fee_monthly_edit = false;
        }

        this.modal.open(this.modalContent_class_edit, { size: 'lg' });

      }, error => {
        console.log("get classes error!" + error);
      });
  }

  delete() {
    let postData = {
      "id": this.classid
    }

    this.http.post('/api/learningcentre/classes/delete', postData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=utf-8',
      })
    }).subscribe(data => {
      window.location.reload();  //refresh
    }, error => {
      console.log(error.status);
    });
  }

  edit() {
    if (this.coursename == '' ||
      this.startdate == '' ||
      this.enddate == '' ||
      this.begintime == '' ||
      this.endtime == '' ||
      this.coursetype == '' ||
      this.address == '') {
      alert('The inputbox can not null!');
    } else if (this.fee_hour == '' &&
      this.fee_time == '' &&
      this.fee_upfront == '' &&
      this.fee_weekly == '' &&
      this.fee_biweekly == '' &&
      this.fee_monthly == '') {
      alert('The fee can not null!');
    } else {
      if (this.model.options == 1) {
        this.paidtype = '1';
        this.paidfee = Number(this.fee_hour).toFixed(2);
      } else if (this.model.options == 2) {
        this.paidtype = '2';
        this.paidfee = Number(this.fee_time).toFixed(2);
      } else if (this.model.options == 3) {
        this.paidtype = '3';
        this.paidfee = Number(this.fee_upfront).toFixed(2);
      } else if (this.model.options == 4) {
        this.paidtype = '4';
        this.paidfee = Number(this.fee_weekly).toFixed(2);
      } else if (this.model.options == 5) {
        this.paidtype = '5';
        this.paidfee = Number(this.fee_biweekly).toFixed(2);
      } else {
        this.paidtype = '6';
        this.paidfee = Number(this.fee_monthly).toFixed(2);
      }
      let postData = {
        "id": this.classid,
        "noremind": this.isChecked,
        "coursename": this.coursename,
        "startdate": this.startdate,
        "enddate": this.enddate,
        "begintime": this.begintime,
        "endtime": this.endtime,
        "coursetype": this.coursetype,
        "address": this.address,
        "paidtype": this.paidtype,
        "paidfee": this.paidfee
      }

      if (isNaN(this.paidfee)) {
        alert('The fee must be number!');
      } else {
        this.http.post('/api/learningcentre/class/edit', postData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json;charset=utf-8',
          })
        })
          .subscribe(data => {
            window.location.reload();  //refresh
          }, error => {
            console.log(error.status);
          });
      }
    }
  }

  isCodeDisabled_fee_hour() {
    return this.is_fee_hour_edit;
  }

  isCodeDisabled_fee_time() {
    return this.is_fee_time_edit;
  }

  isCodeDisabled_fee_upfront() {
    return this.is_fee_upfront_edit;
  }

  isCodeDisabled_fee_weekly() {
    return this.is_fee_weekly_edit;
  }

  isCodeDisabled_fee_biweekly() {
    return this.is_fee_biweekly_edit;
  }

  isCodeDisabled_fee_monthly() {
    return this.is_fee_monthly_edit;
  }

  change_hour() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#000000";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = false;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_time() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#000000";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = false;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_upfront() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#000000";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = false;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_weekly() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#000000";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = false;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_biweekly() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#000000";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = false;
    this.is_fee_monthly_edit = true;
  }

  change_monthly() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#000000";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = false;
  }

  getClassAddress() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    var link = '/api/school/class/address/get/' + this.school;
    console.log(link);
    this.http.get(link)
      .subscribe(address => {
        console.log(address);
        this.classaddress = address[0];

      }, error => {
        console.log("get class address error!" + error);
      });
  }

  getClassesBySchoolId() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    var link = '/api/learningcentre/classes/get/' + this.school;
    console.log(link);
    this.http.get(link)
      .subscribe(data => {
        console.log(data);

        for (var index in data[0]) {

          if (data[0][index].class_type == 'Daily') {

            let class_set_date = new Date(data[0][index].start_date);

            while (class_set_date <= new Date(data[0][index].end_date)) {
              let class_begin = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_begin;
              let class_end = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_end;
              this.class = {
                id: data[0][index].id,
                start: new Date(class_begin),
                end: new Date(class_end),
                title: data[0][index].class_name,
                color: colors.blue,
                actions: this.actions
              };
              this.classes.push(this.class);
              class_set_date = new Date(class_set_date.setDate(class_set_date.getDate() + 1));
            }

          }
          else if (data[0][index].class_type == 'Weekly') {

            let class_set_date = new Date(data[0][index].start_date);

            while (class_set_date <= new Date(data[0][index].end_date)) {
              let class_begin = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_begin;
              let class_end = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_end;
              this.class = {
                id: data[0][index].id,
                start: new Date(class_begin),
                end: new Date(class_end),
                title: data[0][index].class_name,
                color: colors.blue,
                actions: this.actions
              };
              this.classes.push(this.class);
              class_set_date = new Date(class_set_date.setDate(class_set_date.getDate() + 7));
            }

          }
          else if (data[0][index].class_type == 'Biweekly') {

            let class_set_date = new Date(data[0][index].start_date);

            while (class_set_date <= new Date(data[0][index].end_date)) {
              let class_begin = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_begin;
              let class_end = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_end;
              this.class = {
                id: data[0][index].id,
                start: new Date(class_begin),
                end: new Date(class_end),
                title: data[0][index].class_name,
                color: colors.blue,
                actions: this.actions
              };
              this.classes.push(this.class);
              class_set_date = new Date(class_set_date.setDate(class_set_date.getDate() + 14));
            }

          }
          else if (data[0][index].class_type == 'Monthly') {

            let class_set_date = new Date(data[0][index].start_date);

            while (class_set_date <= new Date(data[0][index].end_date)) {
              let class_begin = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_begin;
              let class_end = this.datePipe.transform(class_set_date, 'MM/dd/yyyy') + ' ' + data[0][index].class_time_end;
              this.class = {
                id: data[0][index].id,
                start: new Date(class_begin),
                end: new Date(class_end),
                title: data[0][index].class_name,
                color: colors.blue,
                actions: this.actions
              };
              this.classes.push(this.class);

              let class_set_date_format = this.datePipe.transform(class_set_date, 'yyyy-MM-dd');
              let class_set_date_nextmonth = this.getNextMonthDate(class_set_date_format) + ' ' + '00:00:00';
              class_set_date = new Date(class_set_date_nextmonth);
            }

          }

        }
        this.events = this.classes;

      }, error => {
        console.log("get classes error!" + error);
      });
  }

  //get next month's date
  getNextMonthDate(date) {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份  
    var month = arr[1]; //获取当前日期的月份  
    var day = arr[2]; //获取当前日期的日  
    var days = new Date(year, month, 0);
    var year2 = year;
    var month2 = parseInt(month) + 1;
    var month3 = '';
    var day3 = '';
    if (month2 == 13) {
      year2 = parseInt(year2) + 1;
      month2 = 1;
    }
    var day1 = parseInt(day);
    var days2 = new Date(year2, month2, 0);
    var day2 = days2.getDate();

    if (day1 > day2) {
      day1 = day2;
    }
    if (month2 < 10) {
      month3 = '0' + month2.toString();
    } else {
      month3 = month2.toString();
    }
    if (day1 < 10) {
      day3 = '0' + day1.toString();
    } else {
      day3 = day1.toString();
    }

    var t2 = year2 + '-' + month3 + '-' + day3;
    return t2;
  }

  onChecked($event: any) {
    //alert(this.isChecked);
  }

}
