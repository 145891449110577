import { Component, OnInit, ViewChild, TemplateRef, Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { map, take, filter } from 'rxjs/operators';
import StackBlitzSDK from '@stackblitz/sdk';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { sources as demoUtilsSources } from './demo-modules/demo-utils/sources';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';


interface Source {
  filename: string;
  contents: {
    raw: string;
    highlighted: string;
  };
  language: string;
}

interface Demo {
  label: string;
  path: string;
  sources?: Source[];
  darkTheme: boolean;
  tags: string[];
}

async function getSources(folder: string): Promise<Source[]> {
  const { sources } = await import('./demo-modules/' + folder + '/sources.ts');

  return sources.map(({ filename, contents }) => {
    const [, extension]: RegExpMatchArray = filename.match(/^.+\.(.+)$/);
    const languages: { [extension: string]: string } = {
      ts: 'typescript',
      html: 'html',
      css: 'css'
    };
    return {
      filename,
      contents: {
        raw: contents.raw
          .replace(
            ",\n    RouterModule.forChild([{ path: '', component: DemoComponent }])",
            ''
          )
          .replace("\nimport { RouterModule } from '@angular/router';", ''),
        highlighted: contents.highlighted // TODO - move this into a regexp replace for both
          .replace(
            ',\n    RouterModule.forChild([{ path: <span class="hljs-string">\'\'</span>, component: DemoComponent }])',
            ''
          )
          .replace(
            '\n<span class="hljs-keyword">import</span> { RouterModule } from <span class="hljs-string">\'@angular/router\'</span>;',
            ''
          )
      },
      language: languages[extension]
    };
  });
}

const dependencyVersions: any = {
  angular: require('@angular/core/package.json').version,
  angularRouter: require('@angular/router/package.json').version,
  angularCalendar: require('../../../package.json').version,
  calendarUtils: require('calendar-utils/package.json').version,
  angularResizableElement: require('angular-resizable-element/package.json')
    .version,
  angularDraggableDroppable: require('angular-draggable-droppable/package.json')
    .version,
  dateFns: require('date-fns/package.json').version,
  rxjs: require('rxjs/package.json').version,
  bootstrap: require('bootstrap-css-only/package.json').version,
  zoneJs: require('zone.js/package.json').version,
  ngBootstrap: require('@ng-bootstrap/ng-bootstrap/package.json').version,
  rrule: require('rrule/package.json').version,
  fontAwesome: require('font-awesome/package.json').version,
  positioning: require('positioning/package.json').version,
  flatpickr: require('flatpickr/package.json').version,
  angularxFlatpickr: require('angularx-flatpickr/package.json').version
};

@Component({
  selector: 'mwl-demo-app',
  styleUrls: ['./demo-app.css'],
  templateUrl: './demo-app.html'
})

/*@Injectable({
  providedIn: 'root'
})*/

export class DemoAppComponent implements OnInit {
  @ViewChild('modalAddClass', { static: true }) modalAddClass: TemplateRef<any>;

  demos: Demo[] = [];
  filteredDemos: Demo[] = [];
  activeDemo: Demo;
  isMenuVisible = false;
  firstDemoLoaded = false;
  searchText = '';
  copied$ = new Subject<boolean>();

  coursename: string = '';
  startdate: string = '';
  enddate: string = '';
  begintime: string = '';
  endtime: string = '';
  coursetype: string = '';
  paidtype: string = '';
  paidfee: any;

  sub: any;
  school: string = '';

  model: any = {};
  fee_hour: string = '';
  fee_time: string = '';
  fee_upfront: string = '';
  fee_weekly: string = '';
  fee_biweekly: string = '';
  fee_monthly: string = '';
  is_fee_hour_edit: boolean = false;
  is_fee_time_edit: boolean = true;
  is_fee_upfront_edit: boolean = true;
  is_fee_weekly_edit: boolean = true;
  is_fee_biweekly_edit: boolean = true;
  is_fee_monthly_edit: boolean = true;

  classaddress: any;
  address: string = '';

  constructor(private router: Router,
    analytics: Angulartics2GoogleAnalytics,
    private modal: NgbModal,
    public http: HttpClient,
    private routerInfo: ActivatedRoute
  ) {
    analytics.startTracking();
  }

  ngOnInit() {

    this.routerInfo.queryParams.subscribe(params => {
      this.school = params['school'];
      console.log('school id:' + this.school);
    });
    //this.school = Cookie.get('currentUser');

    setTimeout(() => {
      this.getClassAddress();
    }, 500);

    const defaultRoute = this.router.config.find(route => route.path === '**');

    //radio default charge type
    this.model.options = 1;

    this.demos = this.router.config
      .filter(route => route.path !== '**')
      .map(route => ({
        path: route.path,
        label: route.data.label,
        darkTheme: route.data.darkTheme,
        tags: route.data.tags || []
      }));
    this.updateFilteredDemos();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(take(1))
      .subscribe(() => {
        this.firstDemoLoaded = true;
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .pipe(
        map((event: NavigationStart) => {
          if (event.url === '/') {
            return { url: `/${defaultRoute.redirectTo}` };
          }
          return event;
        })
      )
      .subscribe(async (event: NavigationStart) => {
        this.activeDemo = this.demos.find(
          demo => `/${demo.path}` === event.url
        );
        this.activeDemo.sources = await getSources(this.activeDemo.path);
      });

  }

  updateFilteredDemos() {
    this.filteredDemos = this.demos.filter(
      demo =>
        !this.searchText ||
        [demo.label.toLowerCase(), ...demo.tags].some(tag =>
          tag.includes(this.searchText.toLowerCase())
        )
    );
  }

  editInStackblitz(demo: Demo): void {
    const files: {
      [path: string]: string;
    } = {
      'index.html': `
<link href="https://unpkg.com/bootstrap-css-only@${dependencyVersions.bootstrap}/css/bootstrap.min.css" rel="stylesheet">
<link href="https://unpkg.com/font-awesome@${dependencyVersions.fontAwesome}/css/font-awesome.css" rel="stylesheet">
<link href="https://unpkg.com/angular-calendar@${dependencyVersions.angularCalendar}/css/angular-calendar.css" rel="stylesheet">
<link href="https://unpkg.com/flatpickr@${dependencyVersions.flatpickr}/dist/flatpickr.css" rel="stylesheet">
<mwl-demo-component>Loading...</mwl-demo-component>
`.trim(),
      'main.ts': `
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'zone.js/dist/zone';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { DemoModule } from './demo/module';
import { DemoComponent } from './demo/component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoModule
  ],
  bootstrap: [DemoComponent]
})
export class BootstrapModule {}

platformBrowserDynamic().bootstrapModule(BootstrapModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
}).catch(err => console.error(err));
`.trim()
    };

    demoUtilsSources.forEach(source => {
      files[`demo-utils/${source.filename}`] = source.contents.raw;
    });

    demo.sources.forEach(source => {
      files[`demo/${source.filename}`] = source.contents.raw;
    });

    StackBlitzSDK.openProject(
      {
        title: 'Angular Calendar Demo',
        description: demo.label,
        template: 'angular-cli',
        tags: ['angular-calendar'],
        files,
        dependencies: {
          '@angular/core': dependencyVersions.angular,
          '@angular/common': dependencyVersions.angular,
          '@angular/compiler': dependencyVersions.angular,
          '@angular/platform-browser': dependencyVersions.angular,
          '@angular/platform-browser-dynamic': dependencyVersions.angular,
          '@angular/router': dependencyVersions.angular,
          '@angular/forms': dependencyVersions.angular,
          '@angular/animations': dependencyVersions.angular,
          rxjs: dependencyVersions.rxjs,
          'zone.js': dependencyVersions.zoneJs,
          'angular-draggable-droppable': `^${dependencyVersions.angularDraggableDroppable}`,
          'angular-resizable-element': `^${dependencyVersions.angularResizableElement}`,
          'date-fns': dependencyVersions.dateFns,
          'angular-calendar': dependencyVersions.angularCalendar,
          '@ng-bootstrap/ng-bootstrap': dependencyVersions.ngBootstrap,
          rrule: dependencyVersions.rrule,
          'calendar-utils': dependencyVersions.calendarUtils,
          flatpickr: dependencyVersions.flatpickr,
          'angularx-flatpickr': dependencyVersions.angularxFlatpickr
        }
      },
      {
        openFile: 'demo/component.ts'
      }
    );
  }

  copied() {
    this.copied$.next(true);
    setTimeout(() => {
      this.copied$.next(false);
    }, 1000);
  }

  addClass() {
    this.modal.open(this.modalAddClass, { size: 'lg' });
  }

  goBack() {
    //window.location.href = 'https://localhost:9443/#/dashboard';
    window.location.href = 'https://hyhoo.org/#/dashboard';
  }

  getClassAddress() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    var link = '/api/school/class/address/get/' + this.school;
    console.log(link);
    this.http.get(link)
      .subscribe(address => {
        console.log(address);
        this.classaddress = address[0];

      }, error => {
        console.log("get class address error!" + error);
      });
  }

  save() {
    if (this.coursename == '' ||
      this.startdate == '' ||
      this.enddate == '' ||
      this.begintime == '' ||
      this.endtime == '' ||
      this.coursetype == '' ||
      this.address == '') {
      alert('The inputbox can not null!');
    } else if (this.fee_hour == '' &&
      this.fee_time == '' &&
      this.fee_upfront == '' &&
      this.fee_weekly == '' &&
      this.fee_biweekly == '' &&
      this.fee_monthly == '') {
      alert('The fee can not null!');
    } else {
      if (this.model.options == 1) {
        this.paidtype = '1';
        this.paidfee = Number(this.fee_hour).toFixed(2);
      } else if (this.model.options == 2) {
        this.paidtype = '2';
        this.paidfee = Number(this.fee_time).toFixed(2);
      } else if (this.model.options == 3) {
        this.paidtype = '3';
        this.paidfee = Number(this.fee_upfront).toFixed(2);
      } else if (this.model.options == 4) {
        this.paidtype = '4';
        this.paidfee = Number(this.fee_weekly).toFixed(2);
      } else if (this.model.options == 5) {
        this.paidtype = '5';
        this.paidfee = Number(this.fee_biweekly).toFixed(2);
      } else {
        this.paidtype = '6';
        this.paidfee = Number(this.fee_monthly).toFixed(2);
      }
      let postData = {
        "school": this.school,
        "coursename": this.coursename,
        "startdate": this.startdate,
        "enddate": this.enddate,
        "begintime": this.begintime,
        "endtime": this.endtime,
        "coursetype": this.coursetype,
        "address": this.address,
        "paidtype": this.paidtype,
        "paidfee": this.paidfee
      }

      if (isNaN(this.paidfee)) {
        alert('The fee must be number!');
      } else {
        this.http.post('/api/learningcentre/classes/save', postData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json;charset=utf-8',
          })
        })
          .subscribe(data => {
            window.location.reload();  //refresh
          }, error => {
            console.log(error.status);
          });
      }
    }
  }

  isCodeDisabled_fee_hour() {
    return this.is_fee_hour_edit;
  }

  isCodeDisabled_fee_time() {
    return this.is_fee_time_edit;
  }

  isCodeDisabled_fee_upfront() {
    return this.is_fee_upfront_edit;
  }

  isCodeDisabled_fee_weekly() {
    return this.is_fee_weekly_edit;
  }

  isCodeDisabled_fee_biweekly() {
    return this.is_fee_biweekly_edit;
  }

  isCodeDisabled_fee_monthly() {
    return this.is_fee_monthly_edit;
  }

  change_hour() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#000000";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = false;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_time() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#000000";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = false;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_upfront() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#000000";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = false;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_weekly() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#000000";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = false;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = true;
  }

  change_biweekly() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#000000";
    document.getElementById("label_monthly").style.color = "#808080";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = false;
    this.is_fee_monthly_edit = true;
  }

  change_monthly() {
    this.fee_hour = '';
    this.fee_time = '';
    this.fee_upfront = '';
    this.fee_weekly = '';
    this.fee_biweekly = '';
    this.fee_monthly = '';

    document.getElementById("label_hour").style.color = "#808080";
    document.getElementById("label_time").style.color = "#808080";
    document.getElementById("label_upfront").style.color = "#808080";
    document.getElementById("label_weekly").style.color = "#808080";
    document.getElementById("label_biweekly").style.color = "#808080";
    document.getElementById("label_monthly").style.color = "#000000";

    this.is_fee_hour_edit = true;
    this.is_fee_time_edit = true;
    this.is_fee_upfront_edit = true;
    this.is_fee_weekly_edit = true;
    this.is_fee_biweekly_edit = true;
    this.is_fee_monthly_edit = false;
  }

}
